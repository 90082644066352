.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.local-checkbox {
    transform: scale(1.5);
}

.local-choices {
    display: flex;
    align-items: center;
    font-size: 18px;
}

.select-10-numbers{
    display: flex;
    justify-content: flex-start;
    flex-direction: row!important;
    align-items: center;
    align-content: center;
}
.select-10-numbers > label {
    margin-left: 10px;
    margin-top:5px;
}

.select-10-numbers-field {
    width:100px;
    margin-top: 3px;
    margin-bottom: 3px;
}

.mobile-local-bestel-button{
    display: flex;
    justify-content: center;
    align-items: center;
    position:fixed;
    left:0;
    bottom:0;
    width:100%;
    height:70px;
     border-radius: 0;
    /*background-color:green;*/
    z-index: 5;
    font-weight: 600;
}
