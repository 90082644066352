.homepage{
    border-top: 0;

}

.announcement{
    margin: auto;
    width:100%;
    text-align: center;

    background-color: #fff;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 5px;
}

.subannoucement{
    /*background-color: #fff;*/
	/*border-radius: 4px;*/
	/*padding-top: 10px;*/
    /*padding-bottom: 10px;*/
    /*margin-bottom: 30px;*/

    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

.homebox{
	/*box-shadow:hsl(0, 0%, 80%) 0 0 16px;*/
	background-color: #fff;
	border-radius: 4px;
	padding: 25px;
}

.img-stretch-center{
    width:100%;
}

.subtitle-center{
    text-align: center;
    margin: auto;
}

.restaurant-red{
    color:#870c0a;
}

.list-with-dots{
    list-style-type:square;
    margin-left: 40px;
    max-width: 500px;
}

.image-fit-container{
    max-width:100%;

    /*max-height:300px;*/
    /*padding:5px;*/

}

.col-less-padding{

    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.center-images{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.afhaalbuttons{
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.afhaalbutton{
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reservebutton{
    /*padding: 10px;*/
    display: flex;
    align-items: center;
    justify-content: center;
}

.reservebutton > * {
    width:100%;
    height:50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;

}



.afhaalbutton > * {
    width:100%;
    height:200px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;

}

.google-map {

    width: 400px;
    height: 400px;
    position: relative;
    max-width: 100%;
}

.homepage-cards {
    /*padding-top: -20px;*/
    /*border-bottom: 280px;*/
    /*margin-top: -97px;*/
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width:100%;

}


.homepage-cards > *  {

    /*flex-basis: 50%;*/
    margin: 9px;
}

/*@media only screen and (min-width: 576px) {*/
/*  .homepage-cards > *{*/
/*    flex-grow:2;*/
/*  }*/
/*}*/

/*@media only screen and (min-width: 550px) {*/
/*  .homepage-cards > *{*/
/*    flex-basis: 1%;*/
/*  }*/
/*}*/

/*at this point it becomes 3/1 ratio*/
/*@media only screen and (max-width: 1246px) {*/
/*  .homepage-cards > *{*/

/*     flex-basis: calc(50% - 20px);*/


/*  }*/
/*}*/

/*@media only screen and (max-width: 800px) {*/
/*  .homepage-cards > *{*/
/*    flex-basis: 50%;*/
/*  }*/
/*}*/

/*@media only screen and (min-width: 1200px) {*/
/*  .homepage-cards > *{*/
/*    flex-basis: 100%;*/
/*  }*/
/*}*/

/*.full-width-image {*/
/*   width: 100vw;*/
/*   position: relative;*/
/*   left: 50%;*/
/*   margin-left: -50vw;*/

/*}*/

/*.full-width-image img {*/
/*  width: 100%;*/
/*  height:500px;*/
/*}*/

/*.banner{*/
/*    background-image: url({process.env.PUBLIC_URL + '/images/restaurant-outside.jpg'})*/
/*}*/

.banner {
   /*    width: 99.6vw;*/
   /*position: relative;*/
   /*left: 50%;*/
   /*margin-left: -50vw;*/

    margin-bottom: 20px;
    margin-top: -50px;
    min-height: 160px;
    max-height: 40vw;
    height:calc(100vh - 525px);
    background-position-y: 55%;
    background-size: 100%;
    background-position-x: 20%;
}

.nonhomepage-banner {
   /*    width: 99.6vw;*/
   /*position: relative;*/
   /*left: 50%;*/
   /*margin-left: -50vw;*/

    margin-bottom: 30px;
    margin-top: -50px;
    min-height: 130px;
    max-height: 30vw;
    height:calc(100vh - 675px);
    background-position-y: 61%;
    background-size: 100%;
    background-position-x: 10%;
}

.bijbaan-banner {
   /*    width: 99.6vw;*/
   /*position: relative;*/
   /*left: 50%;*/
   /*margin-left: -50vw;*/

    margin-bottom: 30px;
    margin-top: -50px;
    min-height: 130px;
    max-height: 30vw;
    height:calc(100vh - 675px);
    background-position-y: 15%;
    background-size: 100%;
    background-position-x: 10%;
}

.restaurant-banner {
   /*    width: 99.6vw;*/
   /*position: relative;*/
   /*left: 50%;*/
   /*margin-left: -50vw;*/

    margin-bottom: 30px;
    margin-top: -50px;
    min-height: 130px;
    max-height: 30vw;
    height:calc(100vh - 675px);
    background-position-y: 62%;
    background-size: 100%;
    background-position-x: 10%;
}

.afhalen-banner {
      /*    width: 99.6vw;*/
   /*position: relative;*/
   /*left: 50%;*/
   /*margin-left: -50vw;*/

    margin-bottom: 30px;
    margin-top: -50px;
    min-height: 130px;
    max-height: 30vw;
    height:calc(100vh - 675px);
    background-position-y: 55%;
    background-size: 100%;
    background-position-x: 10%;
}

.catering-banner{
          /*    width: 99.6vw;*/
   /*position: relative;*/
   /*left: 50%;*/
   /*margin-left: -50vw;*/

    margin-bottom: 30px;
    margin-top: -50px;
    min-height: 130px;
    max-height: 30vw;
    height:calc(100vh - 675px);
    background-position-y: 60%;
    background-size: 100%;
    background-position-x: 10%;
}

.contact-banner{
              /*    width: 99.6vw;*/
   /*position: relative;*/
   /*left: 50%;*/
   /*margin-left: -50vw;*/

    margin-bottom: 30px;
    margin-top: -50px;

    max-height: 30vw;
    min-height: 130px;
    height:calc(100vh - 675px);
    background-position-y: 93.5%;
    background-size: 100%;
    background-position-x: 10%;

}

/*.card-stretch-height{*/
/*    !*flex-grow:1;*!*/
/*    !*flex-direction: column;*!*/
/*    !*align-items: stretch;*!*/
/*}*/


