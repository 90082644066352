@media screen and (max-width: 992px) {
    .checkout-container>.row { display: flex; flex-flow: column-reverse; }
}

/*.checkout-row { display: flex; flex-flow: column-reverse; }*/

.checkout-button{
    margin-top: 10px;
    margin-bottom: 25px;
}

.checkout-outercart{
    margin-bottom: 25px;
}


.checkout-container .cart-empty{
    margin-top: 15px;
}