.grecaptcha-badge {
    visibility: hidden;
}

body{
  background-color: #D0D9DD; /*same color as footer!*/
  font-family: 'Roboto';
  /*font-size: 48px;*/
}
.navbarimage{
  background-color: #ffcc0c;
}

.navbarimage img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
}

.mainnavbar{
    background-color: #D10808;
}




.mainpage {
  background-color: #F2F7F2;
  padding-top:50px;
  padding-bottom: 50px;
}

.footer{
  background-color: #D0D9DD;
}

.container{
    min-height: 24vh;
}

.footer> .container {
    min-height: 10vh;
}

.homepage > .container {
    min-height: 40vh;
}

/*.container2{*/
/*    min-height: 50vh;*/
/*}*/

/*.container3{*/
/*    min-height: 50vh;*/
/*}*/


/*@media (min-width: 992px) {*/
/*  .container {*/
/*    max-width: 960px;*/
/*  }*/
/*}*/

/*@media (min-width: 1200px) {*/
/*  .container {*/
/*    max-width: 1140px;*/
/*  }*/
/*}*/

/*big container for webshop*/
@media (min-width: 1460px) {
  .container {
    max-width: 1400px;
  }
}

/*general container*/
@media (min-width: 1460px) {
  .container2 {
    max-width: 1000px;
  }
}

@media (min-width: 1200px) {
  .container2 {
    max-width: 900px;
  }
}

@media (min-width: 1200px) {
  .container3 {
    max-width: 1250px;
  }
}

/*@media (min-width: 1200px) {*/
/*  .container3 {*/
/*    max-width: 900px;*/
/*  }*/
/*}*/

main {
  display:block;
}



.checkoutpage-form div {
  display: flex;
  flex-direction: column;
}

.checkoutpage-form div label{
  color:grey
}

.box{
	/*box-shadow:hsl(0, 0%, 80%) 0 0 16px;*/
	background-color: #fff;
	border-radius: 4px;
	padding: 25px;
}

.price-box{
    background-color: #fff;
	border-radius: 4px;
	padding: 25px;
    padding-bottom: 10px;
    margin-bottom: 30px;

    display: block;
    margin-left: auto;
    margin-right: auto;

    font-weight: 450;
}

.wok-prices{
    margin-left:30px;
}

/*.box-content{*/

/*}*/

/*.btn-light:hover, .btn-light:focus, .btn-light:active, .btn-light.active, .open>.dropdown-toggle.btn-light {*/
/*    !*color: #F3E0DC;*!*/
/*    background-color:  #F6F6F5;*/
/*    !*border-color: #285e8e; !*set the color you want here*!*!*/
/*}*/


.my-select{
  /*position: relative;*/
    width: 100%;
    height: 38px;
    line-height: 38px;
    /*padding: 0 35px 0 8px;*/

    color: #666;
    border: 1px solid #cfcac4;
    border-radius: 3px;
    background-color: #fff;
}

.field-error{
     color: red;
}

.footer{
    padding-top: 35px;
    padding-bottom: 35px;
    /*text-align: center;*/
}

.copyrights{
    text-align: center;
}

/*.footer>.container{*/
/*    margin-left: auto;*/
/*    margin-right:auto;*/
/*}*/

/*.footer-verklaring{*/
/*    color:inherit;*/
/*    text-decoration: underline;*/
/*}*/

/*.footer-verklaring:hover {*/
/*    color:inherit;*/
/*}*/

.footer-icon-area{
    display:flex;
    align-items: center;
}

.footer-icon {
    width:180px;
    max-width: 100%;
    height:auto;
}

/*.footerrow{*/
/*    display: flex;*/
/*    justify-content: center;*/
/*}*/


.a-href-no-style{
    text-decoration: inherit;
    color:inherit;
}

.a-href-no-style:hover {
    color:inherit;
    text-decoration: inherit;
}

.afhaalicon {
    font-size:40px;
}

.followicon {
    font-size:24px;
    margin-right: 10px;
}

.facebookicon{
    margin-right:16px;
    font-size:30px;
}

.lessbold{
    font-weight: 300;
}

.restaurant-smaller-image{
    width:400px;
    height:auto;
}

.center-columns{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

