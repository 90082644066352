.product-banner{
    /*background-color: #fff;*/
    /*padding:10px;*/
    /*padding-left: 18px;*/
    padding-top: 10px;
    padding-bottom: 16px;
}

.product{
    /*box-shadow:hsl(0, 0%, 80%) 0 0 16px;*/
	background-color: #fff;
	/*border-radius: 4px;*/
	/*padding: 18px;*/
    margin-top:6px;
    margin-bottom: 6px;
    /*width:100%;*/

    /*display: flex;*/
    /*justify-content: left;*/
    /*!*flex-direction: column;*!*/
    /*border: 1px solid #dee2e6;*/
    /*border-radius:0px;*/
}

.product-name-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    width:100%;
    padding: 16px;
    cursor: pointer;

}

.product-name-price-left{

}

.product-name-price-right{

}

.product-extra-options {
    /*padding: 30px;*/
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #D8D8D8;
}

.button-extra-options {
    width:100%;
    height:40px;
    /*padding: 10px;*/
    margin-top:20px;
}


a.a-extra-keuzegerechten:link {
    color:#49acec;
    text-decoration: none;
}

a.a-extra-keuzegerechten:visited {
    color:#49acec;
    text-decoration: none;
}

a.a-extra-keuzegerechten:hover {
    color:#49acec;
    font-weight: 500;
    text-decoration: none;
}

.product-text-info {
    font-size: 14px;
}

.product-text-extraoptions {
    font-size: 12px;
    font-style: italic;
}

.product-extra-options-inside {
    display:flex;
    /*justify-content: flex-start;*/
    /*align-items: center;*/
    flex-direction: column;
}

.product-extra-options-inside .my-select{
    /*margin:5px;*/
    height: 35px;
}

.a-extra-keuzegerechten{
    margin-top: 2px;
    margin-bottom: 3px;
}

.keuzegerecht-with-minusbutton{
    display: flex;
    align-items: center;
}

.label-productoptions{
    margin-bottom: 0;
}

.mobile-checkout-button{
    display: flex;
    justify-content: center;
    align-items: center;
    position:fixed;
    left:0;
    bottom:0;
    width:100%;
    height:70px;
     border-radius: 0;
    /*background-color:green;*/
    z-index: 5;
}

/*.mobile-storepage > .container {*/
/*    padding-bottom:20px;*/
/*}*/

#cart-total{
	display: block;
	text-align: center;
	color:#fff;
	background-color: red;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	font-size: 16px;
    padding-top: 1px;
}

#shopping-icon{
	width: 25px;
	 transform: scale(1.4);
}

.shopping-icon-and-quantity{
    margin-right:6px;
}