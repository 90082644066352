

.storepage-outer-cart{
    /*  position: fixed;*/
  position: -webkit-sticky;
  position: sticky;
  /*top: 400px;*/
  /*top:327px;*/
  top:20px;
  margin-top: 73px;
  /*overflow: auto;*/
  /*max-height: 100vh;*/
}

.cart {
  height:100%;
}


/*!* Hide scrollbar for Chrome, Safari and Opera *!*/
/*.outer-cart::-webkit-scrollbar {*/
/*  display: none;*/
/*}*/

/*!* Hide scrollbar for IE, Edge and Firefox *!*/
/*.outer-cart {*/
/*  -ms-overflow-style: none;  !* IE and Edge *!*/
/*  scrollbar-width: none;  !* Firefox *!*/
/*}*/

.cartheader{
  border-bottom: 1px solid #c5ccd3;
  /*height: auto;*/
  padding-bottom: 16px;
}

.storepage-outer-cart .cart-body{
  margin-top:20px;
  max-height: 75vh;
  display:flex;
  flex-direction:column;
    /*overflow-y:scroll;*/
}

.cart-body, ul{
  padding: 0;
  list-style-type: none;

}

.storepage-outer-cart .cartItems{
  margin-bottom: 0;
   /*overflow: auto;*/
  /*display:flex;*/
  /*flex-direction: column;*/
  /*/*box-sizing: border-box;*/
  /*max-height: 45vh;*/
  /*height: 100%;*/
  /*flex: 1 1 auto;*/
  overflow: auto;
}

.total{
  /*flex: 1 1 auto;*/
}

.cartItem{
  /*flex-direction: column;*/
  padding-top: 8px;
  padding-bottom: 8px;
  /*border-bottom: 1px solid #c5ccd3;*/
  display: block;
}



.cartItem-row{
  display:flex;
  /*flex-direction: row;*/
  /*justify-content: space-between;*/
  align-items:flex-start;
  /*align-content: baseline;*/
  /*width:100%;*/
  justify-content: space-between;
  /*flex-wrap:wrap;*/
}

.cartItem-row-leftside{
  /*display: block;*/
  flex-grow:1;
  width:100%;
  word-break:break-word;
}

.cartItem-row-rightside{
  display:flex;
  align-items:center;
  /*flex-grow:1;*/
  /*width:100%;*/
  /*word-break:break-word;*/
}

.cartItem-quantity-name{
  display: flex;
  /*!*flex-direction: row;*!*/
  align-items: center;
}

.cartItem-quantity{
  align-items: center;
  border-radius: 2px;
  display: flex;
  font-weight: 600;
  flex-shrink: 0;
  height: 24px;
  justify-content: center;
  margin-right: 8px;
  width: 24px;
  white-space: nowrap;
  /*width:20px;*/
  /*padding-right: 32px;*/
}



.cartItem-name{
  text-align: left;

  /*padding-top: 4px;*/
  /*padding-bottom: 4px;*/
  /*width:100%;*/

  /*flex: 1 1;*/

  /*word-break: break-word;*/

}

.cart-extra-options{
  display:flex;
  flex-wrap: wrap;
  margin: 0 0 0 32px;
  text-align: left;
  color:#808080;
}

.cartItem-plusminus{
      /*min-width: 28px;*/
}

.cartItem-price{
  /*text-align:right;*/
  /*min-width: 3rem;*/
  /*white-space: nowrap;*/
  /*!*font-size: 13px;*!*/
  text-align: right;
  width:75px;
}

.cartItem-delButton{
 /*min-width: 1.25rem;*/
 /* display: flex;*/
   padding:0;
}

.cartItem-buttons{
  display: flex;
  /*border:0;*/
  flex-direction: row;
  /*flex-shrink: 0;*/
  padding:4px;
  width:34px;
}


.cart-totalPrice{
  margin-top:24px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #c5ccd3;
  padding-top: 20px;

}

.cart-button-div{
  margin-top:24px;
}

.cart-button{
  width: 100%;
}

.cartItem-buttons button{
  /*margin:0;*/
  /*border:0;*/
  padding-left: 0;
  padding-right: 0;
}



/*scroll bar*/
/* width */
.cart ::-webkit-scrollbar {
  width: 13px;

}

/* Track */
.cart ::-webkit-scrollbar-track {
  background: #D3D3D3;
      /*box-shadow: inset 0 0 10px 10px white;*/
    border-left: solid 5px white;
}

/* Handle */
.cart ::-webkit-scrollbar-thumb {
  background: #989898;
  /*padding-left:10px;*/
  /*box-shadow: inset 0 0 10px 10px #555;*/
    border-left: solid 5px white;
    /*border-right: 0;*/
}

/* Handle on hover */
.cart ::-webkit-scrollbar-thumb:hover {
  background: #808080;
}